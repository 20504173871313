import qs from "querystring";

function parseQueryString(str: string, sep?: string, eq?: string, options?: qs.ParseOptions): qs.ParsedUrlQuery {
  return qs.parse(str.replace('?', ''), sep, eq, options);
}

export const ConfigKey = {
  tidKey: '__WPX_TID',
  userTokenKey: '__WPX_UTK',
  userInfoKey: '__WPX_UIF',
  appInfoKey: '__WPX_AIF'
};

export function getConfig(key: string): any {
  if (window[key as any]) {
    return window[key as any];
  }

  const val = localStorage.getItem(key);
  return val ? JSON.parse(val) : val;
}

export function setConfig(key: string, val: any) {
  window[key as any] = val;
  localStorage.setItem(key, val ? JSON.stringify(val) : '');
}

export const BdCode = {

  /**
   * 冲ci
   */
  StageBatchName: [
    '一冲',
    '二冲',
    '三冲',
  ],

  OrgCode: {
    ZC: 'AP003-2',
    CX: 'AP003-3',
  },

  IeSection: 'IE::STAGE_SECTION',
  IeStageType: 'IE::STAGE_TYPE',
  IeStageLevel: 'IE::STAGE_LEVEL',

  OrgGroupType: 'ORG_TYPE::GROUP',

  KBOrg: 'IE::STAGE_KBDW',

  OrgType: {
    Group: 'ORG_TYPE::GROUP',
    Workshop: 'ORG_TYPE::WORKSHOP',
  },

  Quality: {
    ProblemStage: 'QUALITY::PROBLEM_STAGE',
    Problem: 'QUALITY::Problem',
    Process: 'QUALITY::PROCESS',
  },

  SaleOrderType: 'Ordertype',

  StyleSeason: 'STYLE::SEASON',

  MaterialType: {
    /**
     * 主材
     */
    ZC: 'ZC',

    /**
     * 辅材
     */
    FC: 'FC',

    /**
     * 包材
     */
    BZ: 'BZ',

    /**
     * 大底
     */
    DD: 'DD',
  },

  PurchaseStage: {
    /**
     * 大货生产
     */
    Produce: 'STAGE::A1',
    ProduceName: '大货生产',

    /**
     * 开发
     */
    Develop: 'STAGE::A2',

    /**
     * 补数
     */
    Complment: 'STAGE::A4',
    ComplmentName: '补料采购',
  },

  SectionWarehouse: 'IE::DISPATCH::CK',

  SectionWarehouseCode: {
    CB: 'IE::DISPATCH::CK::CB',
    SG: 'IE::DISPATCH::CK::SG',
    WX: 'IE::DISPATCH::WX',
    DNC: 'IE::DISPATCH::CK::DNC',
    CXPTC: 'IE::DISPATCH::CK::CXPTC',
    BLC: 'IE::DISPATCH::CK::BLC',
  },

  CCSection: {
    dataCode: 'STAGE_SECTION::CC',
    dataName: '裁备'
  },

  CXSection: {
    dataCode: 'STAGE_SECTION::CX',
    dataName: '成型'
  },

  ZCSection: {
    dataCode: 'STAGE_SECTION::ZC',
    dataName: '针车'
  },

  SGSection: {
    dataCode: 'STAGE_SECTION::SG',
    dataName: '手工',
  },

  WXSection: {
    dataCode: 'STAGE_SECTION::WX',
    dataName: '外协',
  },

  DNCSection: {
    dataCode: 'STAGE_SECTION::DNC',
    dataName: '电脑车',
  },

  Employee: {
    SalaryField: 'EMPLOYEE::SalaryField',
    Payroll: {
      Time: 'EMPLOYEE:Payroll:Time',
      Piece: 'EMPLOYEE:Payroll:Piece'
    }
  }
};

export function getSectionOptions() {
  return [
    {
      label: BdCode.CCSection.dataName,
      value: BdCode.CCSection.dataCode,
    },
    {
      label: BdCode.WXSection.dataName,
      value: BdCode.WXSection.dataCode,
    },
    {
      label: BdCode.DNCSection.dataName,
      value: BdCode.DNCSection.dataCode,
    },
    {
      label: BdCode.SGSection.dataName,
      value: BdCode.SGSection.dataCode,
    },
    {
      label: BdCode.ZCSection.dataName,
      value: BdCode.ZCSection.dataCode,
    },
    {
      label: BdCode.CXSection.dataName,
      value: BdCode.CXSection.dataCode,
    },

  ];
}

export function getSectionMap() {
  return {
    [BdCode.CCSection.dataCode]: BdCode.CCSection.dataName,
    [BdCode.WXSection.dataCode]: BdCode.WXSection.dataName,
    [BdCode.DNCSection.dataCode]: BdCode.DNCSection.dataName,
    [BdCode.SGSection.dataCode]: BdCode.SGSection.dataName,
    [BdCode.ZCSection.dataCode]: BdCode.ZCSection.dataName,
    [BdCode.CXSection.dataCode]: BdCode.CXSection.dataName,
  };
}

export function getSectionWarehouseCode(sectionCode: string) {
  if (sectionCode === BdCode.ZCSection.dataCode) {
    return BdCode.SectionWarehouseCode.BLC;
  } else if (sectionCode === BdCode.CXSection.dataCode) {
    return BdCode.SectionWarehouseCode.CXPTC;
  } else if (sectionCode === BdCode.DNCSection.dataCode) {
    return BdCode.SectionWarehouseCode.DNC;
  } else if (sectionCode === BdCode.CCSection.dataCode) {
    return BdCode.SectionWarehouseCode.CB;
  } else if (sectionCode === BdCode.SGSection.dataCode) {
    return BdCode.SectionWarehouseCode.SG;
  } else if (sectionCode === BdCode.WXSection.dataCode) {
    return BdCode.SectionWarehouseCode.WX;
  } else {
    return '';
  }
}

export function isUsePartSection (code: string) {
  return code === BdCode.CCSection.dataCode
      || code === BdCode.SGSection.dataCode
      || code === BdCode.WXSection.dataCode
      || code === BdCode.DNCSection.dataCode
}

export function getCurrentUser () {
  return getConfig(ConfigKey.userInfoKey);
}
