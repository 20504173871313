
export default {
  appModel: {
    clientCode: '应用编码',
    clientName: '应用名称',
    rowState: '状态',
    createdAt: '创建日期',
    description: '说明',
    rootUrl: 'URL',
    bdCode: '测试BD',
    fetchCode: '测试Fetch'
  },

  baseDataModel: {
    rowState: '状态',
    createdAt: '创建日期',
    dataCode: '编码',
    dataName: '名称',
    parentId: '父级ID',
    sortNumber: '排序',
    remark: '备注'
  },

  bpmProc: {
    rowState: '状态',
    createdAt: '创建日期',
    procCode: '流程编码',
    procName: '流程名称',
    appCode: '应用编码',
    typeCode: '类型编码'
  },

  bpmProcSource: {
    sourceName: '名称',
    remark: '备注',
    createdAt: '创建日期'
  },

  appResourceModel: {
    clientName: '所属应用',
    parentName: '上级节点',
    resourceCode: '资源代码',
    resourceName: '资源名称',
    typeName: '资源类型',
    sort: '节点排序',
    icon: '图标',
    rowState: '资源状态',
    display: '是否显示',
    description: '备注',
  },

  appRole: {
    roleCode: '角色编码',
    roleName: '角色名称',
    clientName: '所属应用',
    clientId: '所属应用',
    rowState: '状态',
    description: '备注',
  },

  appPerson: {
    userCode: '人员编码',
    userName: '人员名称',
    phone: '手机号码',
    email: '邮箱地址',
    rowState: '状态',
    levelCode: '工种编码',
    levelName: '工种',
    orgCode: '部门编码',
    orgName: '部门',
    stationCode: '岗位编码',
    stationName: '岗位',
  },

  userModel: {
    userCode: '人员编号',
    userName: '人员名称',
    phone: '手机号码',
    email: '邮箱地址',
    stationName: '岗位名称',
    orgName: '组织名称',
    levelName: '职级名称',
    rowState: '状态',
    password: '密码',
    salt: '加密salt'
  },

  roleToUserModel: {
    employeeCode: '人员编码',
    employeeName: '人员名称',
    phone: '手机号码',
    email: '邮箱地址',
    stationName: '岗位',
    orgName: '组织名称',
  },

  departmentOrgModel: {
    orgCode: '部门编码',
    orgName: '部门名称',
    parentId: '上级部门',
    rowState: '状态',
    treePath: '层级路径',
    typeCode: '类型编码',
    typeName: '类型名称',
    attributes: '附加属性',
    // 部门对应的人员表
    email: '邮箱',
    employeeCode: '工号',
    employeeName: '员工姓名',
    phone: '电话',
    stationName: '岗位名称',
    userCode: '人员编号',
    userName: '人员名称',
  },

  baseEmployeeModel: {
    employeeCode: '员工编号',
    employeeName: '员工姓名',
    gender: '性别',
    mainOrgId: '主岗id',
    rowState: '状态',

    // 紧急联系人
    contactRelationshipCode:'联系人关系',
    contactRelationshipCodeName:'联系人关系',
    emergencyContact:'紧急联系人',
    relationshipPhone:'紧急联系人电话',

    // 银行信息
    accountName:'开户名称',
    bankCardNumber:'银行卡号',
    bankOfDeposit:'开户行',
  },

  wareHouseModel: {
    warehouseCode: '仓库编码',
    warehouseName: '仓库名称',
    typeCode: '类型编码',
    typeName: '类型名称',
    address: '仓库地址',
    specification: '规格',
    remark: '备注',
    factoryId: '工厂id',
    factoryCode: '工厂编码',
    factoryName: '仓库所属',
    rowState: '状态',
    createdAt: '创建时间',
  },

  storageLocationModel: {
    posCode: '库位编码',
    posName: '库位名称',
    warehouseId: '仓库id',
    warehouseName: '仓库名称',
    remark: '备注',
    rowState: '状态',
    createdAt: '创建时间',
  },

  ApplyListModel: {
    order: '申请单',
    applyPeople: '申请人',
    type: '类型',
    state: '状态',
    percent: '进度',
    relevance: '关联单据',
    applyDate: '申请时间',
  },

  supplier: {
    supplierCode: '供应商编码',
    supplierName: '供应商名称',
    typeName: '分类',
    procurementRate: '采购税率',
    industry: '行业',
    leadingId: '供应商负责人',
    rowState: '状态',
    remark: '备注'
  },

  supplierContact: {
    contactName: '联系人',
    contactNumber: '联系电话',
    region: '地区',
    address: '详细地址',
    countryName: '国家',
    provinceName: '省份',
    cityName: '城市',
    areaName: '地区',
    telNo: '电话',
    zipCode: '邮编',
    email: '邮箱',
    faxNo: '传真'
  },

  supplierFinancial: {
    paymentMode: '付款方式',
    accountName: '开户名称',
    accountNo: '银行账户',
    accountBank: '开户银行',
    accountAddress: '开户行地址',
    invoiceTitle: '发票抬头',
    taxNo: '税号'
  },

  supplierType: {
    dataName: '分类名称'
  },

  customer: {
    customerCode: '客户编码',
    customerName: '客户名称',
    typeName: '分类',
    procurementRate: '采购税率',
    industry: '行业',
    leadingId: '客户负责人',
    remark: '备注'
  },

  customerContact: {
    contactName: '联系人',
    contactNumber: '联系电话',
    region: '地区',
    address: '详细地址',
    countryName: '国家',
    provinceName: '省份',
    cityName: '城市',
    areaName: '地区',
    telNo: '电话',
    zipCode: '邮编',
    email: '邮箱',
    faxNo: '传真'
  },

  customerFinancial: {
    paymentMode: '付款方式',
    accountName: '开户名称',
    accountNo: '银行账户',
    accountBank: '开户银行',
    accountAddress: '开户行地址',
    invoiceTitle: '发票抬头',
    taxNo: '税号'
  },

  customerType: {
    dataName: '分类名称'
  }
};
