import ExportTypography from "antd/lib/typography/Typography";

export default {
  'Max': '最大不能超过{value}',
  'Min': '最小不能小于{value}',
  'NotBlank': '不能为空',
  'NotEmpty': '不能为空',
  'NotNull': '不能为空',
  'Length': '长度需要在{min}和{max}之间',
};
