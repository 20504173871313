
import AccessFactory, { AccessContext } from '@C/utils/access';
import React, { useMemo } from 'react';
import { useUserState } from 'umi';

const RootWrapper: React.FC<{}> = (props) => {
  const userState = useUserState();
  const accessFn = useMemo(() => AccessFactory(userState), [ userState ]);

  return (
    <AccessContext.Provider value={{
      check: accessFn
    }}>
      { props.children }
    </AccessContext.Provider>
  );
}

export default RootWrapper;
