// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/works/wpublic/mes/mes-pc/main/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@C/components/layout/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/app1",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__layout__EmptyLayout' */'@/components/layout/EmptyLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/app1/pad",
        "microApp": "pad",
        "exact": false,
        "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'pad', base: '/', masterHistoryType: 'browser', routeProps: {'settings':{}} })
        })()
      }
    ]
  },
  {
    "path": "/app",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__layout__BasicLayout' */'@/components/layout/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/app/mes",
        "microApp": "mes",
        "exact": false,
        "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'mes', base: '/', masterHistoryType: 'browser', routeProps: {'settings':{}} })
        })()
      },
      {
        "path": "/app/erp",
        "microApp": "erp",
        "exact": false,
        "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'erp', base: '/', masterHistoryType: 'browser', routeProps: {'settings':{}} })
        })()
      }
    ]
  },
  {
    "path": "/",
    "redirect": "/main/test",
    "exact": true
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'@/pages/Login'), loading: LoadingComponent}),
    "access": false,
    "exact": true
  },
  {
    "path": "/download",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Download' */'@/pages/Download'), loading: LoadingComponent}),
    "access": false,
    "exact": true
  },
  {
    "path": "/main",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__layout__BasicLayout__BasicSiderLayout' */'@/components/layout/BasicLayout/BasicSiderLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/main/page/:confKey",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Ui' */'@/pages/Ui'), loading: LoadingComponent}),
        "access": false,
        "exact": true
      },
      {
        "path": "/main/apps/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apps__List' */'@/pages/Apps/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/test",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test__Excel' */'@/pages/test/Excel'), loading: LoadingComponent}),
        "access": false,
        "exact": true
      },
      {
        "path": "/main/biz/config/so",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BizConfig__SaleOrder' */'@/pages/BizConfig/SaleOrder'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/config/basedata",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Config__BaseData' */'@/pages/Config/BaseData'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/config/bpmn",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Config__Bpmn__List' */'@/pages/Config/Bpmn/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/config/code/config",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Config__Code__Config' */'@/pages/Config/Code/Config'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/supplier/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__List' */'@/pages/Supplier/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/supplier/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__Detail' */'@/pages/Supplier/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/supplier/type",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__Type' */'@/pages/Supplier/Type'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/customer/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__List' */'@/pages/Customer/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/customer/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Detail' */'@/pages/Customer/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/customer/type",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Type' */'@/pages/Customer/Type'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/feed/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Feed__index' */'@/pages/Feed/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/apps/resource",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apps__Resource' */'@/pages/Apps/Resource'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/apps/role",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apps__Role' */'@/pages/Apps/Role'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/org/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__User' */'@/pages/Org/User'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/apps/authorize",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Authorize' */'@/pages/Authorize'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/org/dept",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__Dept' */'@/pages/Org/Dept'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/dataPermiss",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataPermiss' */'@/pages/DataPermiss'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/employee",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__Employee' */'@/pages/Org/Employee'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/employee/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__Employee__Add' */'@/pages/Org/Employee/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/employee/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__Employee__Add' */'@/pages/Org/Employee/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/main/employee/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Org__Employee__Detail' */'@/pages/Org/Employee/Detail'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
