
import React from "react";
import _isString from 'lodash/isString';
import _isFunc from 'lodash/isFunction';
import _isBoolean from 'lodash/isBoolean';

export type AccessItemType = {
  [key: string]: boolean | ((args?: any) => boolean);
};

export type AccessInstanceKey = string | ((accessItems: typeof AccessItems) => boolean);

export type AccessInstanceType = (key: AccessInstanceKey, group?: string, args?: any) => boolean;

export type AccessItemsType = {
  roles: AccessItemType;
  resources: AccessItemType;
  funcs: AccessItemType;
  [key: string]: AccessItemType;
}

export let AccessItems: AccessItemsType = {
  roles: {},
  resources: {},
  funcs: {}
};

export const AccessContext = React.createContext<{
  check: AccessInstanceType
}>({
  check: () => true
});

export type AccessFactoryUserType = { appRoles: any[], appResources: any[] };

export default function AccessFactory(user: AccessFactoryUserType, configs?: {
  funcs?: string[];
}): AccessInstanceType {
  AccessItems = {
    roles: {},
    resources: {},
    funcs: (configs?.funcs || []).reduce((pre: any, cur: any) => {
      pre[cur] = false;
      return pre;
    }, {})
  };

  user.appRoles.forEach(item => {
    AccessItems.roles[item.roleCode] = true;
  });

  user.appResources.forEach(item => {
    AccessItems.resources[item.resourceCode] = true;

    if (item.typeCode === 'FUMCTION') {
      AccessItems.funcs[item.resourceCode] = true;
    }
  });

  return (key, group, args) => {
    // 按钮权限为true为全部开放
    return true;
    // if (_isFunc(key)) {
    //   return key(AccessItems);
    // } else if (_isString(key)) {
    //   const item = AccessItems[group || 'resources'][key];

    //   if (_isFunc(item)) {
    //     return item(args) === true;
    //   } else if (_isBoolean(item)) {
    //     return item === true;
    //   }

    //   return false;
    // }

    // console.warn('Unsupport Key type');
    // return true;
  }
}
