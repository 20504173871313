
export default {
  login: {
    loginName: {
      placeholder: '请输入登录名',
      valid: '请输入登录名'
    },
    password: {
      placeholder: '请输入登录密码',
      valid: '请输入登录密码'
    },
    loginText: '登录'
  },

  user: {
    logout: '退出登录'
  },

  bpmList: {
    tab: {
      proc: '流程列表',
      source: '版本',
      deploy: '部署'
    },
    action: {
      source: '版本信息'
    },
    used: '当前使用',
    show: '查看',
    sort: '排序',
    source: {
      title: '版本信息',
      deploy: '部署'
    }
  },

  supplier: {
    detail: {
      infoTitle: '基本信息',
      contactTitle: '联系信息',
      financinalTitle: '财务信息',
      chooseUser: '选择人员',
      chooseOneUser: '请选择一个人员！'
    }
  },

  customer: {
    detail: {
      infoTitle: '基本信息',
      contactTitle: '联系信息',
      financinalTitle: '财务信息',
      chooseUser: '选择人员',
      chooseOneUser: '请选择一个人员！'
    }
  },

  config: {
    code: {
      config: {
        bizType: '单据类型',
        prefix: '前缀',
        date: '日期格式',
        idLength: '流水号长度',
        step: '流水号步长',
        beginId: '流水号起始数',
        currentId: '流水号当前数',
        example: '示例',
        dateReset: '按日期重置'
      }
    }
  },

  message: {
    userToRole: {
      isNotRole: '请选择人员数据对应的应用数据'
    }
  }
}
