
export default {
  placeholder: {
    selectOnTree: '选择应用',
  },
  message: {
    addResource: '请选择应用！',
    noDeleteNode: '存在子节点不可以删除！',
    selectDeleteNode: '请选择对应节点进行删除！',
    saveResourceCode: '该资源代码已经存在',

  },
  card: {
    title: '资源信息',
  },
};
