
import _isString from 'lodash/isString';
import zhCN from '@C/locales/zh-CN';

import ui from './zhCN/ui';
import api from './zhCN/api';
import code from './zhCN/code';
import enums from './zhCN/enums';
import page from './zhCN/page';
import valid from './zhCN/validation';
import vm from './zhCN/vm';
import resource from './zhCN/resource';
import dept from './zhCN/dept';


function formatMessage(messages: any, msgs: any, prefix: string) {
  Object.keys(msgs).forEach(key => {
    const val = msgs[key];
    if (_isString(val)) {
      messages[`${prefix}.${key}`] = val;
    } else {
      formatMessage(messages, val, `${prefix}.${key}`);
    }
  });
}

function buildMessage(msgs: any, module: string) {
  const messages: any = {};
  formatMessage(messages, msgs, module);
  return messages;
}

export default {
  ...zhCN,
  ...buildMessage(ui, 'ui'),
  ...buildMessage(api, 'api'),
  ...buildMessage(code, 'api.code'),
  ...buildMessage(enums, 'enums'),
  ...buildMessage(page, 'page'),
  ...buildMessage(valid, 'valid'),
  ...buildMessage(vm, 'vm'),
  ...buildMessage(resource, 'resource'),
  ...buildMessage(dept, 'dept'),
};
