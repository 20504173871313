
export default {
  loading: '加载中...',
  title: '比升智能管理平台',
  button: {
    add: '新增',
    addon: '添加',
    edit: '编辑',
    delete: '删除',
    batchDelete: '批量删除',
    clear: '清空',
    save: '保存',
    addResource: '添加资源',
    addUser: '添加人员',
    cancel: '取消',
    insert: '插入',
    audit: '审核',
    revokeAudit: '反审',
    setUsed: '默认',
    import: '导入',
    export: '导出',
    back: '返回',
    config: '配置',
    submit: '提交',
  },
  excel: {
    import: {
      next: '下一步',
      pre: '上一步',
      cancel: '取消',
      done: '完成',
      reValid: '重新校验',
      retry: '重试',
      valid: {
        title: '错误信息',
        emptyExcel: 'Excel没有数据',
        required: '{title}必填',
        success: {
          title: '校验通过',
          message: '共导入数据{count}条'
        },
        error: {
          title: '校验失败',
          message: '共导入数据{count}条，其中有{error}处数据错误，请在下方表格修改数据（也可以调整文件重新上传）'
        }
      },
      upload: {
        title: '上传文件',
        subTitle: '请上传指定格式文件',
        downTitle: '下载模板',
        downSubTitle: '点击下方按钮，下载模板，并按照规定格式填写数据，每次填写数据总数不超过{max}条',
        downButton: '下载模板',
        upTitle: '上传文件',
        upSubTitle: '上传文件仅支持xls，xlsx格式，且文件大小不超过{max}',
        upSubSizeTitle: '上传文件的大小不能超过{max}',
        upButton: '选择文件',
        upNull: '不能上传空文件',
        upSelectType: '支持上传格式：{title}',
      },

      menu: {
        rowAbove: '上方插入',
        rowBelow: '下方插入',
        removeRow: '删除行'
      },

      check: {
        title: '校验数据',
        subTitle: '检查数据格式是否正确'
      },

      finish: {
        title: '写入数据',
        subTitle: '执行数据写入',
        info: {
          title: '数据写入中...',
          message: '共导入数据{count}条'
        },
        success: {
          title: '数据写入成功'
        },
        error: {
          title: '数据写入失败'
        }
      }
    }
  },
  bpmn: {
    selectUser: '选择人员',
    table: {
      sort: '排序',
      taskCode: '节点编码',
      taskName: '节点名称',
      taskAuditType: '审批类型',
      taskUsers: '审批设置',
      remark: '备注',
      insertAbove: '上方插入',
      insertBelow: '下方插入',
      source: {
        name: '版本名称：',
        remark: '备注：'
      }
    }
  },
  modal: {
    title: {
      edit: '编辑',
      confirm: '确认',
      addUser: '添加人员',
      error: '错误'
    },
    content: {
      selAppRole: '请先选择应用及角色'
    },
    confirmDelete: {
      title: '确认删除',
      content: '确定需要删除吗？',
      okText: '确定',
      cancelText: '取消',
    }
  },
  table: {
    row: {
      action: '操作',
      edit: '编辑',
      delete: '删除',
      deleteConfirm: '确认删除数据？',
      resetPW: '重置密码',
      add: '添加',
      added: '已添加',
      remove: '移除'
    },
    pagination: {
      showTotal: {
        text: '共 {total} 条数据'
      }
    }
  },
  tab: {
    roleToUser: '角色绑人',
    userToRole: '人绑角色',
    roleAuthorize: '角色授权'
  },
  select: {
    placeholder: {
      selClient: '选择应用'
    }
  },
  tabPane: {
    tab: {
      waitChoice: '待选',
      alreadyChoice: '已选',
    }
  },
  upload: {
    uploadFile: '上传文件',
  },
  addonCard: {
    label: {
      attachment: '附件',
      remark: '备注'
    }
  },
  form: {
    rules: {
      input: '请填写{title}',
      select: '请选择{title}',
    }
  },

  span: {
    selectContext: '已选{key}项',
  },
};
